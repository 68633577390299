.byClickText {
  font-size: 1.6rem;
  display: flex;
  color: #595959;
  margin-top: 1.2rem;
  flex-direction: row;
  justify-content: center;
}

.byClickText > p {
  margin: 0px;
}

.termAndConditions {
  margin: auto 4px;
}

.alreadyHaveAnAccount {
  text-align: center;
}
.haveAnAccountText {
  font-size: 1.8rem;
  color: #595959;
}

@media (max-width: 480px) {
  .byClickText {
    font-size: 1.5rem;
    flex-direction: column;
    text-align: center;
  }
}
